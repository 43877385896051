<template>
  <div class="main-block">
    <div class="main-block-title">UBO (Ultimate Beneficial Owner)</div>
    <div class="main-block-content">

      <ul v-if="registrationData.member.errors['UBO']" class="summary">
        <li class="error" v-for="error in registrationData.member.errors['UBO']" :key="error">{{ error }}</li>
      </ul>

      <div class="ubos" v-if="!isEditMode">
        <!-- START: UBO -->
        <div class="ubo" v-for="(people, index) in peoples" :key="people.id" @click="edit(people.id)" :data-test="'ubo-' + index">
          <div class="ubo-percent">{{ people.percent }}%</div>
          <div class="ubo-name">{{ people.first_name }} {{ people.last_name }}</div>
          <div class="ubo-email">{{ people.email }}</div>
          <ol class="ubo-actions">
            <li>
              <button type="button" class="btn-edit" data-test="edit">
                <icon-edit color="#19191C" size="21" />
                <span>Edit</span>
              </button>
            </li>
            <li>
              <button type="button" class="btn-delete" @click.stop="remove(people.id)" data-test="delete">
                <icon-remove color="#19191C" size="21" />
                <span>Delete</span>
              </button>
            </li>
          </ol>
        </div>
        <!-- END: UBO -->

        <!-- START: new UBO -->
        <div class="ubo new" @click="add()" data-test="add">
          <button class="btn-add">
            <span>+ Add UBO</span>
          </button>
        </div>
        <!-- END: new UBO -->
      </div>

      <div v-else>
        <person v-model="person" :errors.sync="errors" />
      </div>

      <div class="form-buttons text-right" :class="{many: isEditMode}">
        <div class="pull-left" v-if="isEditMode">
          <button type="button" class="btn-back" @click="person = null" data-test="back">
            <icon-next color="#19191C" size="15"/>
            <span>Back</span>
          </button>
        </div>
        <div class="pull-right">
          <button type="submit" class="btn-next" @click="submit" data-test="submit">
            <span>Save and proceed</span>
            <icon-next color="#19191C" size="15"/>
          </button>
        </div>
      </div>

    </div>

    <transition name="tab-change-fade">
      <div class="main-block-overload" v-show="showOverload" data-test="is_director_modal">
        <div>
          <p>Is the Director/Attorney <br>also the UBO?</p>
          <button class="btn btn-yellow btn-bordered" @click="loadDirector">Yes</button>
          <button class="btn btn-default" @click="showOverload = false">No</button>
        </div>
      </div>
    </transition>

    <transition name="tab-change-fade">
      <div class="main-block-overload" v-show="showDeleteOverload" data-test="delete_modal">
        <div>
          <p>Are you sure you want to delete UBO?</p>
          <button class="btn btn-yellow btn-bordered" @click="remove(showDeleteOverload, true)">Yes</button>
          <button class="btn btn-default" @click="showDeleteOverload = false">No</button>
        </div>
      </div>
    </transition>

    <transition name="tab-change-fade">
      <div class="main-block-overload" v-show="showUboOverload" data-test="all_data_filled_modal">
        <div>
          <icon-close size="24" color="#999" class="overlay-close" @click.native="showUboOverload = false"/>
          <p class="p-image-line">
            <idea />
          </p>
          <p class="p-first-line">Are you sure you have specified all UBOs?</p>
          <p class="p-second-line">UBO you have specified owns only {{ percentSum }}% of shares out of 100%</p>
          <button class="btn btn-default" @click="$emit('next-step')" data-test="yes_im_sure">Yes, I'm sure</button>
          <button class="btn btn-yellow btn-bordered" @click="clickOverlayAddUbo" data-test="add_ubo">Add UBO</button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Idea from '@/assets/images/Idea';
import IconEdit from "@/assets/icons/IconEdit";
import IconNext from "@/assets/icons/IconNext";
import RandomIndexMixin from "@/components/RandomIndexMixin";
import {mapGetters, mapState} from "vuex";
import Person from "@/components/Person";
import IconClose from "@/assets/icons/IconClose";
import IconRemove from "@/assets/icons/IconRemove";

const TYPE_UBO = 'UBO'
const TYPE_DIRECTOR = 'DIRECTOR'

export default {
  name: 'UBO',
  mixins: [RandomIndexMixin],
  components: {
    IconRemove,
    IconClose,
    Person,
    IconEdit,
    Idea,
    IconNext
  },
  data () {
    return {
      person: null,
      errors: [],

      showOverload: false,
      showUboOverload: false,
      showDeleteOverload: false,

      newPerson: {
        birthday: '',
        middle_name: '',
        last_name: '',
        first_name: '',
        gender: '',
        citizenship_country_id: '',
        email: '',
        phone: '',
        is_pep: '',
        is_us: '',
        percent: '',
        type: 'UBO',
        country_id: '',
        city: '',
        street: '',
        postal_code: '',
        house: '',
        apartment: '',
        files: [],
        address_confirmation_files: [],
        source_funds_files: []
      }
    }
  },
  mounted () {
    if ((this.peoples.length === 0) && this.registrationData.member.isCompleted['DIRECTOR']) {
      this.showOverload = true
    }
  },
  computed: {
    ...mapGetters(['getRegistrationDataPart']),
    ...mapState({
      registrationHash: state => state.registration.hash,
      registrationData: state => state.registration.data,

      // Default list data
      genderList: state => state.init.genders,
      boolAnswers: state => state.init.boolAnswers,
      typeList: state => state.init.memberTypes,
      countries: state => state.countries.list
    }),

    peoples () {
      return this.getRegistrationDataPart('member').items.filter(item => {
        return item.attributes.type === TYPE_UBO
      }).map(i => {
        return i.attributes
      })
    },

    isEditMode () {
      return !!this.person
    },

    percentSum () {
      let percent = 0

      if (this.peoples.length > 0) {
        for (let people of this.peoples) {
          percent = percent + parseInt(people.percent)
        }
      }

      return percent
    }
  },
  methods: {
    add () {
      this.person = JSON.parse(JSON.stringify(this.newPerson))
    },
    edit (id) {
      this.person = this.peoples.find(i => i.id === id)
    },
    submit () {
      if (this.isEditMode) {
        this.save().then(response => {

          if (Object.keys(response.data).length > 0) {
            this.errors = response.data
          } else {
            this.$store.dispatch('updateRegistration').finally(() => {
              this.person = null
            })
          }

          return response
        })
      } else {
        if (this.percentSum !== 100) {
          this.showUboOverload = true
        } else {
          this.$emit('next-step')
        }
      }
    },

    /**
     * Remove UBO button
     */
    remove (id, force = false) {
      if (force) {
        this.$sdk.registration.member.delete(this.registrationHash, this.showDeleteOverload).then(() => {
          this.$store.dispatch('updateRegistration').then(() => {
            this.showDeleteOverload = false
          })
        })
      } else {
        this.showDeleteOverload = id
      }
    },

    /**
     * Делаем не реактивную копию директора
     * @param director
     */
    copyDirector (director) {
      let cp = {...director}

      for (const key of ['files', 'address_confirmation_files']) {
        if (Array.isArray(cp[key])) {
          cp[key] = [...cp[key]]

          for (const i in cp[key]) {
            cp[key][i] = {...cp[key][i]}
          }
        }
      }

      return cp
    },

    loadDirector () {
      let director = this.getRegistrationDataPart('member').items.find(i => {
        return i.attributes.type === TYPE_DIRECTOR
      })

      if (director) {
        let directorCopy = this.copyDirector(director.attributes)
        delete directorCopy.id

        directorCopy.address_confirmation_files = directorCopy.address_confirmation_files || []
        directorCopy.type = TYPE_UBO

        this.person = directorCopy
        this.showOverload = false
      }
    },

    /**
     * Click add UBO at the UBO's modal window
     */
    clickOverlayAddUbo () {
      this.showUboOverload = false
      this.add()
    },

    save () {
      let formData = {
        birthday: this.person.birthday,
        middle_name: this.person.middle_name,
        last_name: this.person.last_name,
        first_name: this.person.first_name,
        gender: this.person.gender,
        citizenship_country_id: this.person.citizenship_country_id,
        email: this.person.email,
        phone: this.person.phone,
        is_pep: this.person.is_pep,
        is_us: this.person.is_us,
        type: this.person.type,
        country_id: this.person.country_id,
        city: this.person.city,
        street: this.person.street,
        postal_code: this.person.postal_code,
        house: this.person.house,
        apartment: this.person.apartment,
        percent: this.person.percent,
        files: this.person.files,
        id: this.person.id,
        address_confirmation_files: this.person.address_confirmation_files,
        source_funds_files: this.person.source_funds_files
      }

      let promise = undefined

      if (this.person.id) {
        promise = this.$sdk.registration.member.update(this.registrationHash, formData.id, formData, 0)
      } else {
        promise = this.$sdk.registration.member.create(this.registrationHash, formData, 0).then(response => {
          if (Array.isArray(response.data) && response.data.length === 0) {
            this.person.id = response.headers['x-id']
          }

          return response
        })
      }

      return promise
    }
  }
}
</script>


<style lang="less">

.ubos {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 40px;

  @media all and (max-width: 1000px) {
    grid-template-columns: repeat(1, 1fr);
  }

  .ubo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all .2s ease;

    position: relative;
    text-align: center;
    border: 1px solid #FDE46A;
    padding: 70px 40px 88px;
    height: 250px;
    box-sizing: border-box;
    border-radius: 2px;

    &-percent {
      font-size: 30px;
      font-weight: 600;
    }

    &-name {
      font-size: 20px;
      font-weight: 500;
      margin: 10px 0 6px;
      line-height: 1.4em;
    }
    &-email {
      font-size: 13px;
    }

    &-actions {
      width: calc(100% - 90px);
      position: absolute;
      bottom: 30px;
      display: flex;
      grid-column-gap: 20px;
      justify-content: center;
    }

    &.new {
      box-sizing: border-box;
      border: 6px dashed rgba(0, 0, 0, 0.2);
      cursor: pointer;
      padding: 70px 40px;

      &:before {
        content: ' ';
        display: block;
        background: #FFF;
        width: calc(100% + 10px);
        height: calc(100% + 10px);
        position: absolute;
        top: -5px;
        left: -5px;
      }
    }

    &:hover {
      box-shadow: 0 9px 18px 0 rgba(26,26,26,0.18);
      cursor: pointer;
    }
  }
}

.btn-delete,
.btn-edit {
  display: inline-flex;
  grid-column-gap: 6px;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  span {
    font-size: 14px;
    font-weight: bolder;
  }

  &:hover {
    color: #00000099;

    svg path {
      fill: #00000099
    }
  }
}

.btn-back {
  display: inline-flex;
  grid-column-gap: 3px;

  svg {
    transform: rotate(90deg);
  }
}

.btn-add {
  color: #000000;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  z-index: 2;
  position: relative;
  padding-top: 5px;
}

.btn-remove {
  color: #EA4C17;
  font-size: 14px;
  padding: 0 8px;

  position: relative;
  background-color: #fff;

  left: calc(100% - 70px);
  top: -50px;

  .icon-close {
    margin-left: 5px;
    position: relative;
    top: -1px;
  }

  .icon-close,
  span {
    vertical-align: middle;
  }
}

.btn-add-more {
  font-size: 14px;
  margin: 30px 0 0;

  .icon-add {
    margin-right: 10px;
    vertical-align: middle;
  }

  & > span {
    vertical-align: middle;
    position: relative;
  }
}

.many {
  display: flex;
  justify-content: space-between;
}

</style>

<style lang="less" scoped>
.p {
  &-image-line {
    padding-bottom: 10px;
  }
  &-first-list {
    font-size: 20px;
  }
  &-second-line {
    font-size: 14px;
    font-weight: normal;
  }
}

.overlay-close {
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
}
</style>
