<template>
  <svg :width="size" :height="size" viewBox="0 0 18 18">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="2-saved-card" transform="translate(-250.000000, -484.000000)">
        <g id="Card-1" transform="translate(120.000000, 282.000000)">
          <g id="outline-delete-24px" transform="translate(130.000000, 202.000000)">
            <polygon id="Path" points="0 0 18 0 18 18 0 18"></polygon>
            <path d="M12,6.75 L12,14.25 L6,14.25 L6,6.75 L12,6.75 L12,6.75 Z M10.875,2.25 L7.125,2.25 L6.375,3 L3.75,3 L3.75,4.5 L14.25,4.5 L14.25,3 L11.625,3 L10.875,2.25 Z M13.5,5.25 L4.5,5.25 L4.5,14.25 C4.5,15.075 5.175,15.75 6,15.75 L12,15.75 C12.825,15.75 13.5,15.075 13.5,14.25 L13.5,5.25 Z" id="Shape" fill="#19191C" fill-rule="nonzero"></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconRemove',
  props: {
    color: {
      type: String,
      default: '#fff'
    },
    size: {
      type: [String, Number],
      default: 18
    }
  }
}
</script>

