<template>
  <svg :width="size" :height="size" viewBox="0 0 18 18">
    <g id="Artboard" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="pencil-(2)">
        <polygon id="Path" points="0 0 18 0 18 18 0 18"></polygon>
        <path d="M14.6877862,5.62619332 L13.7620219,6.55197178 L11.447611,4.23466828 L12.3733753,3.30888982 C12.561957,3.12030532 12.8905462,3.12030532 13.0791278,3.30888982 L14.6877862,4.9204301 C14.8820824,5.11472929 14.8820824,5.43189413 14.6877862,5.62619332 Z M6.95308228,13.3695872 L4.63867142,11.0522837 L10.6218521,5.06043981 L12.9362629,7.37774331 L6.95308228,13.3695872 Z M4.10435682,12.1695041 L5.83587901,13.90391 L3.42432005,14.5839571 L4.10435682,12.1695041 Z M15.5135451,4.09465858 L13.9048867,2.48311829 C13.590584,2.16881079 12.456237,1.57448387 11.5504737,2.48311829 L3.39860438,10.6408267 C3.32717194,10.7122602 3.27574059,10.7979804 3.25002491,10.89513 L2.02138705,15.269719 C1.96424111,15.4725902 2.02424435,15.6897481 2.16996652,15.8411872 C2.31854598,15.9926262 2.6157049,16.0183423 2.74142599,15.9897689 L7.11309093,14.7582549 C7.21023904,14.7325389 7.29595796,14.6811067 7.36739039,14.6096732 L15.5135451,6.45196485 C16.1621516,5.80334845 16.1621516,4.74613231 15.5135451,4.09465858 Z" id="Shape" fill="#000000" fill-rule="nonzero"></path>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconEdit',
  props: {
    color: {
      type: String,
      default: '#fff'
    },
    size: {
      type: [String, Number],
      default: 18
    }
  }
}
</script>

